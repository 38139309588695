<template>
    <div>
        <cTop></cTop>
        <div class="container">
            <div class="nav-wrap"><div class="wrap" @click="() => {$router.push('/')}"><img class="logo" tabindex="0" src="../../assets/img/logo.png"><span class="head-title" tabindex="0">修改手机号</span></div></div>
            <div class="container-content">
                <div class="page-grid">
        <div class = "alain-pro__page-grid">
        <a-spin :spinning="false">
            <div style = "width: 90%; margin: 5rem auto;">
                <a-row>
                    <a-col :span = "8" :offset="8">
                        <a-steps :current="currentStep" labelPlacement="vertical">
                          <a-step v-for="item in steps" :key="item.title" :title="item.title" />
                        </a-steps>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8" :offset="8">
                        <div style = "margin: 3rem auto 5rem;">
                            <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" v-if="currentStep == 0">
                                <a-form-item label="手机号">
                                    {{userInfo.mobile}}
                                </a-form-item>
                                <a-form-item label="验证码" required>
                                    <a-input v-model="verify_code" placeholder="请输入验证码" size="large">
                                        <div slot="suffix" class="getVerifyCode" :class="countDown<60 ? 'disableColor' : ''" @click="getVerifyCode(userInfo.mobile, 3)">
                                            {{getVerifyCodeText}}
                                        </div>
                                    </a-input>
                                </a-form-item>
                                <a-form-item>
                                    <a-row>
                                        <a-col :span="16" offset="7">
                                            <a-button :disabled="verify_code.length != 6" @click="verifyPhone">下一步</a-button>
                                        </a-col>
                                    </a-row>
                                </a-form-item>
                            </a-form>
                            <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" v-if="currentStep == 1">
                                <a-form-item label="新手机号" required>
                                    <a-input v-model="newPhone.phone" placeholder="请输入新手机号" size="large"></a-input>
                                </a-form-item>
                                <a-form-item label="验证码" required>
                                    <a-input v-model="newPhone.verify_code" placeholder="请输入验证码" size="large">
                                        <div slot="suffix" class="getVerifyCode" :class="countDown<60 ? 'disableColor' : ''" @click="getVerifyCode(newPhone.phone, 4)">
                                            {{getVerifyCodeText}}
                                        </div>
                                    </a-input>
                                </a-form-item>
                                <a-form-item>
                                    <a-row>
                                        <a-col :span="16" offset="7">
                                            <a-button :disabled="newPhone.verify_code.length != 6" @click="setNewPhone">下一步</a-button>
                                        </a-col>
                                    </a-row>
                                </a-form-item>
                            </a-form>
                            <a-form  :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" v-if="currentStep == 2">
                                <a-result
                                        status="success"
                                        title="绑定成功"
                                >
                                </a-result>
                            </a-form>

                        </div>
                    </a-col>
                </a-row>
            </div>
        </a-spin>
    </div>
    </div>
            </div>
        </div>
    </div>

</template>

<script>
import cTop from "@/component/top";
import service from "../../../utils/request";

export default {
    name: "my-phone",
    components: {
        cTop,
    },
    data() {
        return {
            currentStep: 0,
            userInfo:{},
            steps:[
                {title:'安全验证'},
                {title:'设置新号'},
                {title:'完成'},
            ],
            getVerifyCodeText:'获取验证码',
            countDown: 60,
            verify_code:'',
            newPhone:{
                phone:'',
                verify_code: '',
            },
            timer: null
        }
    },
    created() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    },
    methods: {
        getVerifyCode(phone, type){
            if(this.countDown < 60) return false;
            service.post(service.uri.user.getVerifyCode, {phone: phone, type: type}).then(() => {

                this.timer = setInterval(() => {
                    this.countDown--;
                    if(this.countDown <= 0){
                        this.getVerifyCodeText = '获取验证码';
                        this.countDown = 60;
                        clearInterval(this.timer);
                        return ;
                    }
                    this.getVerifyCodeText = '请等待'+this.countDown+'S';
                }, 1000);
            });
        },
        verifyPhone(){
            service.post(service.uri.user.verifyPhone, {verify_code: this.verify_code, type: 3}).then(res => {
                if(res.code == 200){
                    clearInterval(this.timer);
                    this.countDown = 60;
                    this.getVerifyCodeText = '获取验证码';
                    this.currentStep = 1;
                    this.verify_code = '';
                }
            });
        },
        setNewPhone(){
            service.post(service.uri.user.setNewPhone, {phone: this.newPhone.phone, verify_code: this.newPhone.verify_code}).then(res => {
                if(res.code == 200){
                    clearInterval(this.timer);
                    this.countDown = 60;
                    this.getVerifyCodeText = '获取验证码';
                    this.currentStep = 2;
                    this.newPhone.verify_code = '';
                    let userCache = JSON.parse(localStorage.getItem('userInfo'));
                    userCache.mobile = this.newPhone.phone;
                    localStorage.setItem('userInfo', JSON.stringify(userCache));
                }
            });
        }
    }
}
</script>

<style lang = "less" scoped>

.wrap {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
}
.logo {
    width: 240px;
    height: 60px;
    margin-left: 2rem;
}
.container {
    background-image: url("../../assets/img/bg-logo.png");
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% 100%;
    min-height: 600px;
    .nav-wrap {
        width: 100%;
        background-color: #fff;
        .wrap {
            padding: 14px 0 12px;
            .head-title {
                margin-left: 1rem;
                color: #333;
                font-weight: 700;
                font-size: 20px;
                vertical-align: middle;
            }
        }
    }
    .container-content {
        flex: 1;
    }
}
.alain-pro__page-grid {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 100%;
    transition: .3s;
}

.page-grid {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #fff;

}
    .alain-pro__page-grid {
        background: #f2f3f5 url('../../assets/img/bg-password.png') no-repeat 50%/100%;
    }
    .getVerifyCode{
        cursor: pointer;
        color: @primary-color;
    }
    .getVerifyCode.disableColor{
        color: @disabled-color;
    }
</style>